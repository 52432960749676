import React from 'react';
import { requestEmailVerification } from 'store/emailVerification/emailVerificationActions';
import { Button } from '@mui/material';
import { warningPopup } from './warningPopup';


import i18n from '../../i18n';


export const triggerVerifyEmailPopup = (emailAddress, id, dispatch) => {
  const popupBody = (
    <div>
      <Button
        type="button"
        size="small"
        color="secondary"
        variant="text"
        onClick={() => {
          dispatch(requestEmailVerification(emailAddress));
        }}
      >
        {
          i18n.getDataByLanguage(i18n.language)['translation.notification']
            .SendVerificationEmail
        }
      </Button>
    </div>
  );

  dispatch(
    warningPopup(
      `${
        i18n.getDataByLanguage(i18n.language)['translation.notification']
          .VerifyEmail
      } ${emailAddress}`,
      0,
      `email-verification-${id}`,
      popupBody
    )
  );
};
